/**
 * Typescript definition for api configurations
 */
export type API_CONFIG = {
  baseUrl: string
  createOrganization: string
  deleteOrganization: string
  createOrganizationUser: string
  deleteUser: string
  deleteWorkspace: string
  deleteWorkspaceUser: string
  deleteWorkspaceUsers: string
  updateWorkspace: string
  getOrganization: string
  getOrganizations: string
  getOrganizationUsers: string
  getOrganizationUser: string
  getOrganizationWorkspaces: string
  getUser: string
  getWorkspace: string
  getWorkspaceTools: string
  getWorkspaceUsers: string
  postWorkspaceUsers: string
  modifyOrganization: string
  postWorkspace: string
  putWorkspaceUser: string
  updateUser: string
}

/**
 *
 * Enum for api endpoints
 */
export enum ENDPOINTS {
  /**
   *
   * Endpoint to GET and POST a new organization
   */
  ORGANIZATIONS = '/organization',

  /**
   *
   * Endpoint to GET, PUT, DELETE an existing organization
   */
  ORGANIZATION = '/organization/$organizationId',

  /**
   *
   * Endpoint to GET and POST users in an organization
   */
  ORGANIZATION_USERS = '/organization/$organizationId/user',

  /**
   *
   * Endpoint to GET, PUT and DELETE and existing user in an organization
   */
  ORGANIZATION_USER = '/organization/$organizationId/user/$userId',

  /**
   *
   * Endpoint to GET and POST workspaces in an organization
   */
  ORGANIZATION_WORKSPACES = '/organization/$organizationId/workspace',

  /**
   *
   * Endpoint to GET, PUT and DELETE workspace in an organization
   */
  ORGANIZATION_WORKSPACE = '/organization/$organizationId/workspace/$workspaceId',

  /**
   *
   * Endpoint to GET workspace Cloud Api Status
   */
  ORGANIZATION_WORKSPACE_CLOUD_API_STATUS = '/organization/$organizationId/workspace/$workspaceId/cloudApiStatus',

  /**
   *
   * Endpoint to resubmit workspace Cloud Api resources
   */
  ORGANIZATION_WORKSPACE_CLOUD_API_RESOURCES = '/organization/$organizationId/workspace/$workspaceId/resubmitCloudApiResources',

  /**
   *
   * Endpoint to GET an existing user
   */
  USER = '/user/$userId',

  /**
   *
   * Endpoint to GET and POST users in a workspace
   */
  WORKSPACE_USERS = '/organization/$organizationId/workspace/$workspaceId/user',

  /**
   *
   * Remove users from workspace
   */

  DELETE_WORKSPACE_USERS = '/organization/$organizationId/workspace/$workspaceId/users',

  /**
   *
   * Endpoint to PUT, DELETE and GET a user from a workspace
   */
  WORKSPACE_USER = '/organization/$organizationId/workspace/$workspaceId/user/$userId',

  /**
   * Endpoint to GET and POST workspace data
   */
  WORKSPACE_DATA = '/data',

  /**
   *
   * Data endpoint
   */
  WORKSPACE_DATA_UPDATE = '/data/workspace',

  /**
   * Endpoint to GET, PUT and DELETE workspace data
   */
  WORKSPACE_DATA_MODEL = '/data/$dataId',

  /**
   *
   * Endpoint to get Tools in an organization
   */
  ORGANIZATION_TOOLS = '/organization/$organizationId/workspace/$workspaceId/tool',

  /**
   *
   * Endpoint to execute a query synchronously
   */
  QUERY = '/query',

  /**
   *
   * Endpoint to start a query execution
   */
  START_QUERY = '/start-query',
  /**
   *
   * Endpoint to check query's status
   */
  CHECK_QUERY = '/check-query',
  /**
   *
   * Endpoint to get results of query
   */
  GET_QUERY_RESULTS = '/get-query-results',
  /**
   *
   * Endpoint to stop a query
   */
  STOP_QUERY = '/stop-query',

  QUERY_METRICS = '/organization/$organizationId/$fromDate/orgCostMetrics/?useIndex=true',
}
